<template>
  <div>
    <b-form-checkbox
      v-if="!$store.state.app.isMaster($store) && canUpdateLimit"
      v-model="isAutoUpdateLimitPrice"
      name="check-button"
      class="mb-2"
      switch
      inline
      @change="changeAutoUpdateLimit"
    >
      Auto Update Limit
    </b-form-checkbox>
    <b-card>
      <b-card-text>
        <b-row
          class="justify-content-start align-items-center  text-center text-sm-left"
          no-gutters
        >
          <b-col
            sm="auto"
          >
            <span style="font-size: 1.286rem">อั้นราคา {{ name }}</span>
          </b-col>
          <b-col
            sm="auto"
            class="mt-50 mt-sm-0 text-center"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="relief-primary"
              class="ml-50"
              @click="DownloadDefault"
            >
              <span>Default Setting</span>
            </b-button>
          </b-col>
        </b-row>

      </b-card-text>
      <div>
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
        >
          <b-row
            v-for="(item, index) in limit"
            :id="item.id"
            :key="index"
            ref="row"
            class="text-center align-items-center"
          >

            <b-col md="3">
              <v-select
                v-model="item.type"
                label="title"
                :clearable="false"
                :options="options"
                class="mb-1"
                append-to-body
                placeholder="ประเภทการเเทง"
              />
            </b-col>

            <b-col md="2">
              <b-form-input
                v-model="item.numberLimit"
                type="number"
                placeholder="ราคาที่จะลิมิตต่อเลข (Ex.5000)"
                class="mb-1"
              />
            </b-col>

            <b-col md="2">
              <b-form-input
                v-model="item.percen"
                type="number"
                placeholder="เปอร์เซ็นที่จะจ่าย (Ex.50)"
                class="mb-1"
              />
            </b-col>

            <b-col md="2">
              <b-form-input
                v-model="item.numberClose"
                type="number"
                placeholder="ราคาที่จะลิมิตปิดเเทง (Ex.5000)"
                class="mb-1"
              />
            </b-col>

            <b-col
              lg="2"
              md="3"
              class="mb-1 text-left text-md-center"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                @click="removeLimit(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Delete</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

          <b-row
            class="text-center align-items-center"
          >

            <b-col md="3">
              <v-select
                v-model="addNewForm.type"
                label="title"
                :clearable="false"
                :options="options"
                class="mb-1"
                append-to-body
                placeholder="ประเภทการเเทง"
              />
            </b-col>

            <b-col md="2">
              <b-form-input
                v-model="addNewForm.numberLimit"
                type="number"
                placeholder="ราคาที่จะลิมิตต่อเลข (Ex.5000)"
                class="mb-1"
              />
            </b-col>

            <b-col md="2">
              <b-form-input
                v-model="addNewForm.percen"
                type="number"
                placeholder="เปอร์เซ็นที่จะจ่าย (Ex.50)"
                class="mb-1"
              />
            </b-col>

            <b-col md="2">
              <b-form-input
                v-model="addNewForm.numberClose"
                type="number"
                placeholder="ราคาที่จะลิมิตปิดเเทง (Ex.5000)"
                class="mb-1"
              />
            </b-col>

            <b-col
              lg="2"
              md="3"
              class="mb-1 text-left text-md-center"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-success"
                class="text-nowrap"
                @click="addLimit"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
                />
                <span>Add New</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>

        </b-form>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="dark"
          class="mb-50 mr-50"
          @click="$router.go(-1)"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            class="mr-25"
          />
          <span>ย้อนกลับ</span>
        </b-button>
        <b-button
          v-if="$store.state.app.isMaster($store)"
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="relief-primary"
          class="ml-50 mb-50 mr-50"
          @click="BroadcastUpdate"
        >
          <span>Broadcast Update</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="ml-50 mb-50"
          @click="save"
        >
          <feather-icon
            icon="SaveIcon"
            class="ml-25"
          />
          <span>บันทึกข้อมูล</span>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BForm, BFormInput, BRow, BCol, BButton, BCard, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import lottoService from '@/service/lottoService'

const { BFormCheckbox } = require('bootstrap-vue')

export default {
  components: {
    BFormCheckbox,
    BCard,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isAutoUpdateLimitPrice: false,
      canUpdateLimit: false,
      items: [{
        id: 1,
        selected: 'male',
        selected1: 'designer',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      loaded: true,
      options: [
        'สามตัวบน',
        'สามตัวโต้ด',
        'สามตัวหน้า',
        'สามตัวท้าย',
        'สองตัวบน',
        'สองตัวล่าง',
        'วิ่งบน',
        'วิ่งล่าง',
      ],
      mapValue: {
        สามตัวบน: 1,
        สามตัวโต้ด: 2,
        สามตัวหน้า: 3,
        สามตัวท้าย: 4,
        สองตัวบน: 5,
        สองตัวล่าง: 6,
        วิ่งบน: 7,
        วิ่งล่าง: 8,
      },
      name: '',
      huayId: '',
      limit: [],
      addNewForm: {
        huayId: 0,
        type: null,
        numberLimit: null,
        percen: null,
        numberClose: null,
        numberClosePerUser: 0,
      },
    }
  },
  async mounted() {
    const { id } = this.$route.query
    if (id) {
      this.huayId = id
    }
    if (!this.$store.state.app.isMaster(this.$store)) {
      lottoService.getAuthen(`limitPrice/auto/${id}`)
        .then(res => {
          if (res.status === 200) {
            this.isAutoUpdateLimitPrice = res.data.isAutoUpdate
            this.canUpdateLimit = res.data.canUpdateLimit
          }
        })
    }
    await this.reloadData()
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async reloadData() {
      this.loaded = true
      const { id } = this.$route.query
      if (id) {
        const res = await lottoService.getAuthen(`huay/limit/id/${id}`)
        if (res.status === 200) {
          this.name = res.data.name
          // eslint-disable-next-line no-restricted-syntax
          for (const l of res.data.limit) {
            this.limit.push({
              id: l.id,
              huayId: l.huayId,
              type: this.options[l.type - 1],
              numberLimit: l.numberLimit,
              percen: l.percen,
              numberClose: l.numberClose,
              numberClosePerUser: l.numberClosePerUser,
            })
          }
        }
        setTimeout(() => {
          this.loaded = false
        }, 100)
      }
    },
    addLimit() {
      const { id } = this.$route.query
      if (id) {
        if (this.addNewForm.type === null || this.addNewForm.numberLimit === null || this.addNewForm.percen === null || this.addNewForm.numberClose === null) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: 'กรุณากรอกข้อมูลให้ถูกต้อง',
            },
          })
          return
        }
        this.limit.push({
          huayId: id,
          type: this.addNewForm.type,
          numberLimit: this.addNewForm.numberLimit,
          percen: this.addNewForm.percen,
          numberClose: this.addNewForm.numberClose,
          numberClosePerUser: this.addNewForm.numberClosePerUser,
        })

        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        })

        this.addNewForm.type = null
        this.addNewForm.numberLimit = null
        this.addNewForm.percen = null
        this.addNewForm.numberClose = null
      }
    },
    removeLimit(index) {
      this.limit.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    async save() {
      const { id } = this.$route.query
      if (id) {
        this.$swal({
          dark: true,
          title: 'Are you sure?',
          text: 'คุณเเน่ใจว่าต้องการบันทึกข้อมูลใช่หรือไม่',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const limits = []
            // eslint-disable-next-line no-restricted-syntax
            for (const l of this.limit) {
              limits.push({
                // id: l.id,
                huayId: l.huayId,
                type: this.mapValue[l.type] * 1,
                numberLimit: l.numberLimit * 1,
                percen: l.percen * 1,
                numberClose: l.numberClose * 1,
                numberClosePerUser: l.numberClosePerUser * 1,
              })
            }
            const res = await lottoService.putAuthen(
              `huay/limit/id/${id}`,
              limits,
            )
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'บันทึกข้อมูสำเร็จ',
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async DownloadDefault() {
      if (this.huayId === '') {
        return
      }
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการใช้การตั้งค่าพื้นฐานใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          if (this.$store.state.app.isMaster(this.$store)) {
            this.loadPreset()
          } else {
            this.loaded = true
            const res = await lottoService.getAuthen(`limit/load-raw/${this.huayId}`)
            setTimeout(() => {
              if (res.status === 200) {
                this.limit = []
                // eslint-disable-next-line no-restricted-syntax
                for (const l of res.data.limit) {
                  this.limit.push({
                    id: l.id,
                    huayId: l.huayId,
                    type: this.options[l.type - 1],
                    numberLimit: l.numberLimit,
                    percen: l.percen,
                    numberClose: l.numberClose,
                    numberClosePerUser: l.numberClosePerUser,
                  })
                }
                this.initTrHeight()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ดำเนินการสำเร็จ',
                  },
                })
              } else if (res.status === 422) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'success',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'ดำเนินการสำเร็จ',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'ขออภัย เกิดความผิดพลาดบางอย่าง โปรดลองใหม่อีกครั้งในภายหลัง',
                  },
                })
              }
              this.loaded = false
            }, 100)
          }
        }
      })
    },
    async loadPreset() {
      const res = await fetch(`https://api77lotto.com/lotto/api/huay/limit/id?name=${this.name}`)
      if (res.status === 200) {
        const data = await res.json()
        this.loaded = true
        setTimeout(() => {
          if (res.status === 200) {
            this.limit = []
            // eslint-disable-next-line no-restricted-syntax
            for (const l of data.limit) {
              this.limit.push({
                id: l.id,
                huayId: l.huayId,
                type: this.options[l.type - 1],
                numberLimit: l.numberLimit,
                percen: l.percen,
                numberClose: l.numberClose,
                numberClosePerUser: l.numberClosePerUser,
              })
            }
            this.initTrHeight()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'success',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'ดำเนินการสำเร็จ',
              },
            })
          } else if (res.status === 422) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'success',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'ดำเนินการสำเร็จ',
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: 'ขออภัย เกิดความผิดพลาดบางอย่าง โปรดลองใหม่อีกครั้งในภายหลัง',
              },
            })
          }
          this.loaded = false
        }, 100)
      }
    },
    changeAutoUpdateLimit(e) {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการดำเนินการใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.isConfirmed) {
            const res = await lottoService.getAuthen(`limitPrice/${this.huayId}`)
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'ดำเนินการสำเร็จ',
                },
              })
            } else {
              this.isAutoUpdateLimitPrice = !e
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          } else {
            this.isAutoUpdateLimitPrice = !e
          }
        })
    },
    async BroadcastUpdate() {
      this.$swal({
        dark: true,
        title: 'Are you sure?',
        text: 'คุณเเน่ใจว่าต้องการเเจ้งอัพเดตข้อมูลไปที่ตัวเเทนทั้งหมดใช่หรือไม่',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            const res = await lottoService.getAuthen(`limitPrice/broadcast/${this.huayId}`)
            if (res.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: res.data,
                },
              })
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: res.data,
                },
              })
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
