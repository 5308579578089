/* eslint-disable no-underscore-dangle,no-param-reassign */

import Cookies from 'js-cookie'
import jwt from '@/auth/jwt/useJwt'

const tokenName = 'accessToken'

export default {
  base_url: '',
  isJSON(text) {
    if (typeof text !== 'string') {
      return false
    }
    try {
      JSON.parse(text)
      return true
    } catch (error) {
      return false
    }
  },
  async toObject(req) {
    const result = {
      status: req.status,
      statusText: req.statusText,
      data: null,
    }
    const text = await req.text()
    if (this.isJSON(text)) {
      result.data = JSON.parse(text)
    } else {
      result.data = text
    }
    return result
  },
  buildHeader(req) {
    const auth = Cookies.get(tokenName)
    if (auth) {
      req.headers = {
        ...req.headers,
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${auth}`,
      }
    } else {
      req.headers = {
        ...req.headers,
        'Content-Type': 'application/json; charset=utf-8',
      }
    }
    return req
  },
  async send(url, req) {
    let res = await this.toObject(await fetch(url, this.buildHeader(req)))
    if (res.status === 401) {
      const resToken = await jwt.refreshToken()
      const { data } = resToken
      jwt.setToken(data.accessToken)
      jwt.setRefreshToken(data.refreshToken)
      res = await this.toObject(await fetch(url, this.buildHeader(req)))
      if (res.status === 401) {
        jwt.removeToken()
        window.location.reload()
      } else if (res.status === 503) {
        jwt.removeToken()
        window.location.reload()
      }
    } else if (res.status === 503) {
      jwt.removeToken()
      window.location.reload()
    }
    return res
  },
  async post(url, data) {
    return this.send(this.base_url + url, { method: 'post', body: JSON.stringify(data) })
  },
  async postAuthen(url, data) {
    return this.send(this.base_url + url, { method: 'post', body: JSON.stringify(data) })
  },
  async postFileAuthen(url, data, message) {
    const formData = new FormData()
    for (let i = 0; i < data.length; i += 1) {
      formData.append('file', data[i].file)
    }
    formData.append('message', message)
    return this.send(this.base_url + url, { method: 'post', body: formData })
  },
  async put(url, data) {
    return this.send(this.base_url + url, { method: 'put', body: JSON.stringify(data) })
  },
  async putAuthen(url, data) {
    return this.send(this.base_url + url, { method: 'put', body: JSON.stringify(data) })
  },
  async get(url) {
    try {
      return this.send(this.base_url + url, { method: 'get' })
    } catch (ex) {
      return {
        status: 500,
        data: null,
      }
    }
  },
  async getAuthen(url) {
    return this.send(this.base_url + url, { method: 'get' })
  },
  async delete(url) {
    return this.send(this.base_url + url, { method: 'delete' })
  },
  async deleteAuthen(url) {
    return this.send(this.base_url + url, { method: 'delete' })
  },
}
